import React, { FC, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import {
  PageLayout,
  SlideInUpleftImage,
  SlideInUpRightImage,
  SlideInZoomTitle,
  CTALink,
} from 'components';
import { SingleFlower } from 'assets/tsx/single-flower';
import Img from 'gatsby-image';

const Blog: FC = ({ data }: any) => {
  const BlogList = data.BlogList.edges;
  const {
    title,
    description,
    seo,
    callToAction: { link, title: callToActionTitle },
    quotation: { content: quotation },
  } = data.BlogPage;

  const Limit = 4;
  const [BlogToDisplay, setBlogToDisplay] = useState(6);
  const [showMore, setShowMore] = useState(false);

  const loadMoreBlog = () => {
    const newIndex = BlogToDisplay + Limit;
    const newShowMore = newIndex < BlogList.length - 1;
    setBlogToDisplay(newIndex);
    setShowMore(newShowMore);
  };

  useEffect(() => {
    document.body.classList.add('blog-page');
    if (BlogList.length > BlogToDisplay) {
      setShowMore(true);
    }
    return () => {
      document.body.classList.remove('blog-page');
    };
  }, []);
  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      quotation={quotation}
      icon={<SingleFlower />}
      className="container"
    >
      <div className="blog-page">
        <h4 className="page-title"> &gt; {title}</h4>
        <div className="page-description">
          <p>{description}</p>
        </div>
      </div>
      <div className="blog_section">
        <ul className="blog_items">
          {BlogList &&
            BlogList.slice(0, BlogToDisplay).map((item: any, index: number) => {
              return (
                <li key={item.node.id} className="blog_items_wrapper">
                  {item?.node?.bannerimage?.childImageSharp?.fluid && (
                    <Link to={item.node.slug}>
                      {index % 2 === 0 ? (
                        <SlideInUpleftImage>
                          <Img
                            className="blog_items_wrapper_banner"
                            fluid={item.node.bannerimage.childImageSharp.fluid}
                          />
                        </SlideInUpleftImage>
                      ) : (
                        <SlideInUpRightImage>
                          <Img
                            className="blog_items_wrapper_banner"
                            fluid={item.node.bannerimage.childImageSharp.fluid}
                          />
                        </SlideInUpRightImage>
                      )}
                    </Link>
                  )}
                  <div className="blog_items_title link">
                    <SlideInZoomTitle>
                      <Link to={item.node.slug}>{item.node.title}</Link>
                    </SlideInZoomTitle>
                  </div>
                </li>
              );
            })}
        </ul>
        {showMore && (
          <button
            type="button"
            className="blog_items_angle_down"
            onClick={() => loadMoreBlog()}
          >
            Load more
          </button>
        )}
      </div>
      <CTALink to={link}>{callToActionTitle}</CTALink>
    </PageLayout>
  );
};

export default Blog;

export const query = graphql`
  query BlogListQuery {
    BlogPage: strapiBlogPage {
      title
      description
      callToAction {
        link
        title
      }
      quotation {
        content
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
    }
    BlogList: allStrapiArticle(sort: { fields: orderNumber }) {
      edges {
        node {
          id
          orderNumber
          title
          slug
          authorName
          bannerimage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
